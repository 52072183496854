import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import MediaLayout from "../../components/media-layout"
import {FormattedMessage} from "react-intl";
import nissan_now_cover from "../../assets/images/entertainment/Nissan-now-magazine-cover@2x-min.jpg"

import {useIntl, Link} from "gatsby-plugin-intl";


class BodyClass extends React.Component {

  componentDidMount() {
    document.body.classList.add(this.props.name);
  }
  componentWillUnmount() {
    document.body.classList.remove(this.props.name);
  }
  render() {
    return this.props.children || null;
  }
}


const Entertainment = ({location}) => {
  const intl = useIntl();

  const pageContent = () => {
    return(
      <div className="col-lg-8">

        <div className="white-container">

          <h1 className="text-uppercase with-border-top with-border-bottom fs-20"><FormattedMessage id="entertainment.title"/></h1>

          <p className="mb-4"><FormattedMessage id="entertainment.description"/></p>

          <div className="row">
            <div className="col-md-12 mb-4">

              <div className="card card-my-reward">
                <a href={intl.formatMessage({id: "entertainment.link"})} className="" target="_blank">
                  <div className="img-wrapper">
                    <img src={nissan_now_cover}  className="card-img-top" alt=<FormattedMessage id="entertainment.title"/> />
                  </div>
                  <div className="card-body">
                    <div className="card-title text"><strong className="darker-grey"><FormattedMessage id="entertainment.nissannow.title"/></strong></div>
                    <p className="card-text"><FormattedMessage id="entertainment.nissannow.description"/></p>
                    <a className="button red" href={intl.formatMessage({id: "entertainment.link"})} target="_blank"><FormattedMessage id="entertainment.read_button"/></a>
                  </div>
                </a>
              </div>

            </div>

          </div>

        </div>
      </div>
    )
  }

  return(
    <Layout>
      <SEO title={intl.formatMessage({ id: "pages_meta_title.entertainment.my-rewards" })} />
      <BodyClass name="large-wrapper" />

      <MediaLayout children={pageContent()} currentPage="entertainment" />

      <Link to="/contact/how-can-help-you" className="btn-contact"><span className="icon-questions"></span></Link>

    </Layout>
  )
}

export default Entertainment
